<i18n>
{
  "en": {
    "title": "New Bill",
    "input-1": "Description",
    "input-2": "Total",
    "input-3": "Status",
    "input-4": "Method",
    "input-5": "Type",
    "create": "Create bill"
  },
  "pt-BR": {
    "title": "Novo Lançamento",
    "input-1": "Descrição",
    "input-2": "Total",
    "input-3": "Status",
    "input-4": "Método",
    "input-5": "Tipo",
    "create": "Criar lançamento"
  }
}
</i18n>

<template>
  <modal-action @close="closeCreateBill" :columns="2">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="file-invoice-dollar" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeCreateBill" />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="form" v-model="createValid">
            <div class="fields">
              <we-input
                class="double-column"
                v-model="description"
                isRequired
                type="text"
                :label="$t('input-1')"
                cy="create-bill-name"
              />
              <we-input
                v-model="total"
                isRequired
                type="money"
                :label="$t('input-2')"
                cy="create-bill-money"
              />
              <we-input
                v-model="status"
                isRequired
                type="global-select"
                :label="$t('input-3')"
                :items="billStatusList"
                cy="create-bill-status"
              />
              <we-input
                v-model="method"
                isRequired
                type="global-select"
                :label="$t('input-4')"
                :items="billMethodsList"
                cy="create-bill-method"
              />
              <we-input
                v-model="type"
                isRequired
                type="global-select"
                :label="$t('input-5')"
                :items="billTypesList"
                cy="create-bill-type"
              />
            </div>
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('create')"
            icon="plus"
            :loading="loadingBtn"
            @click="createBill"
            :disabled="!createValid"
            cy="create-bill"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      createValid: false,
      loadingBtn: false,
      loading: true,
      description: "",
      total: 0,
      status: "",
      method: "",
      type: ""
    };
  },
  computed: {
    billMethodsList() {
      return this.$store.getters.getBillMethodsList;
    },
    billStatusList() {
      return this.$store.getters.getBillStatusList;
    },
    billTypesList() {
      return this.$store.getters.getBillTypesList;
    }
  },
  methods: {
    ...mapActions(["createBillRequest"]),
    closeCreateBill() {
      this.$router.push({ name: "bills" });
    },
    async createBill() {
      this.loadingBtn = true;

      const payload = {
        description: this.description,
        total: this.total,
        method: this.method,
        status: this.status,
        type: this.type,
        user_id: 0
      };

      const response = await this.createBillRequest(payload);

      if (response?.data?.id) {
        this.$router.push({
          name: "bill",
          params: { billId: response.data.id },
          query: { reload: true }
        });
      }
      this.loadingBtn = false;
    }
  },
  async mounted() {
    this.loading = false;
  }
};
</script>
