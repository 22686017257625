<i18n>
{
  "en": {
    "delete-title": "Delete user",
    "data-section-1": "User Details",
    "data-section-2": "Roles",
    "data-section-3": "Location",
    "data-section-4": "Bank Details",
    "data-section-5": "Custom Fields",
    "data-section-input-1": "Name",
    "data-section-input-2": "Surname",
    "data-section-input-3": "Gender",
    "data-section-input-4": "Birthdate",
    "data-section-input-5": "Main phone",
    "data-section-input-6": "Secondary phone",
    "data-section-input-7": "E-mail",
    "data-section-input-8": "Occupation",
    "data-section-input-9": "Enabled user",
    "data-section-input-10": "User type",
    "data-section-input-11": "Enabled pipelines",
    "data-section-input-12": "Restrict contacts and companies",
    "data-section-input-28": "Habilitar atribuição automática de cards",
    "data-section-input-13": "CEP",
    "data-section-input-14": "Address",
    "data-section-input-15": "Number",
    "data-section-input-16": "District",
    "data-section-input-17": "Complements",
    "data-section-input-18": "City",
    "data-section-input-19": "State",
    "data-section-input-20": "Country",
    "data-section-input-21": "Bank name",
    "data-section-input-22": "Code",
    "data-section-input-23": "Agency",
    "data-section-input-24": "Account",
    "data-section-input-25": "Account type",
    "data-section-input-26": "Account owner",
    "data-section-input-27": "Owner document",
    "attachments-section": "Attachments",
    "activities-section": "Activities",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "save": "Save updates",
    "save-mobile": "Save",
    "gender-type-1": "male",
    "gender-type-2": "female",
    "bank-account-type-1": "Checking",
    "bank-account-type-2": "Savings",
    "bank-account-owner-1": "Yes",
    "bank-account-owner-2": "No",
    "user-active-1": "Yes",
    "user-active-2": "No"
  },
  "pt-BR": {
    "delete-title": "Deletar usuário",
    "data-section-1": "Dados Pessoais",
    "data-section-2": "Permissões",
    "data-section-3": "Localização",
    "data-section-4": "Dados Bancários",
    "data-section-5": "Campos Customizados",
    "data-section-input-1": "Nome",
    "data-section-input-2": "Sobrenome",
    "data-section-input-3": "Gênero",
    "data-section-input-4": "Data de nascimento",
    "data-section-input-5": "Telefone principal",
    "data-section-input-6": "Telefone secundário",
    "data-section-input-7": "E-mail",
    "data-section-input-8": "Profissão",
    "data-section-input-9": "Habilitar usuário",
    "data-section-input-10": "Tipo de usuário",
    "data-section-input-11": "Pipelines habilitados",
    "data-section-input-12": "Restringir contatos e empresas",
    "data-section-input-28": "Habilitar atribuição automática de cards",
    "data-section-input-13": "CEP",
    "data-section-input-14": "Rua",
    "data-section-input-15": "Número",
    "data-section-input-16": "Bairro",
    "data-section-input-17": "Complemento",
    "data-section-input-18": "Cidade",
    "data-section-input-19": "Estado",
    "data-section-input-20": "País",
    "data-section-input-21": "Nome do banco",
    "data-section-input-22": "Código",
    "data-section-input-23": "Agência",
    "data-section-input-24": "Conta",
    "data-section-input-25": "Tipo de Conta",
    "data-section-input-26": "Favorecido da conta",
    "data-section-input-27": "Documento do favorecido",
    "attachments-section": "Anexos",
    "activities-section": "Atividades",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "save": "Salvar alterações",
    "save-mobile": "Salvar",
    "gender-type-1": "masculino",
    "gender-type-2": "feminino",
    "bank-account-type-1": "Corrente",
    "bank-account-type-2": "Poupança",
    "bank-account-owner-1": "Sim",
    "bank-account-owner-2": "Não",
    "user-active-1": "Sim",
    "user-active-2": "Não"

  }
}
</i18n>

<template>
  <modal-entity
    class="user"
    @close="closeUser"
    @drop="drop"
    :menuItems="menuItems"
    @currentMenuItem="currentMenuItem = $event"
  >
    <template #actions>
      <confirm-modal
        :open="confirmDeleteOpened"
        @close="confirmDeleteOpened = false"
        @confirm="deleteUser(user.id)"
      />
      <we-icon-button
        :icon="['far', 'trash-alt']"
        :name="$t('delete-title')"
        direction="bottom"
        @click="confirmDeleteOpened = true"
        cy="delete-user"
        class="big-mobile"
      />
      <we-icon-button icon="times" class="big-mobile" @click="closeUser" />
    </template>
    <template #data>
      <div class="data-grid">
        <we-picture-change
          type="square"
          size="79"
          :name="user.first_name"
          :picture="user.picture"
          :loading="imgLoading"
          @onFileChange="onFileChange"
          @delete="deleteUserImage"
        />
        <div class="data-info">
          <we-text-tooltip
            :text="`${user.first_name} ${user.last_name}`"
            class="name"
          />
          <div class="data-list">
            <span class="email">{{ user.email }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <v-form ref="form" v-model="createValid">
        <section
          id="profile"
          name="profile"
          :class="{ 'hide-mobile': currentMenuItem != 'profile' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="user" />
            </div>
            <div class="section-name">{{ $t("data-section-1") }}</div>
          </div>
          <div class="fields">
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.first_name"
              isRequired
              type="text"
              :label="$t('data-section-input-1')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              type="text"
              v-model="user.last_name"
              isRequired
              :label="$t('data-section-input-2')"
            />
            <we-input
              :label="$t('data-section-input-3')"
              type="tag"
              v-model="user.gender"
              :items="[$t('gender-type-1'), $t('gender-type-2')]"
            />
            <we-input
              v-model="user.birthdate"
              type="date"
              :label="$t('data-section-input-4')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.phone1"
              type="text"
              :label="$t('data-section-input-5')"
              :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.phone2"
              type="text"
              :label="$t('data-section-input-6')"
              :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              class="fill-input"
              v-model="user.email"
              isRequired
              type="email"
              :label="$t('data-section-input-7')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.job"
              type="text"
              :label="$t('data-section-input-8')"
            />
          </div>
        </section>
        <section
          id="permissions"
          name="permissions"
          v-if="['admin', 'manager'].includes(loggedUser.role)"
          :class="{ 'hide-mobile': currentMenuItem != 'permissions' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="key" />
            </div>
            <div class="section-name">{{ $t("data-section-2") }}</div>
          </div>
          <div class="fields">
            <v-switch
              class="fill-input"
              v-model="user.is_active"
              :label="$t('data-section-input-9')"
              hide-details
            />
            <v-switch
              class="fill-input"
              v-model="user.is_auto_card_assignment_enabled"
              :label="$t('data-section-input-28')"
              hide-details
            />
            <we-input
              type="global-select"
              v-model="user.role"
              isRequired
              :items="roleList"
              :label="$t('data-section-input-10')"
              @input="changeRole"
            />
            <we-input
              type="global-multiselect"
              v-model="user.pipelines"
              :items="pipelinesList"
              :label="$t('data-section-input-11')"
            />
            <v-switch
              v-if="
                [
                  'restricted agent',
                  'external agent',
                  'agent',
                  'visitor'
                ].includes(user.role)
              "
              class="fill-input"
              v-model="user.is_data_access_restricted"
              :label="$t('data-section-input-12')"
              hide-details
            />
          </div>
        </section>
        <section
          id="address"
          name="address"
          :class="{ 'hide-mobile': currentMenuItem != 'address' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="globe-americas" />
            </div>
            <div class="section-name">{{ $t("data-section-3") }}</div>
          </div>
          <div class="fields">
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.address_zipcode"
              type="text"
              :label="$t('data-section-input-13')"
              :mask="$root.$t('zipcode-mask')"
              @blur="getCep($event)"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.address_street"
              type="text"
              :label="$t('data-section-input-14')"
              :loading="cepLoading"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.address_number"
              :mask="'######'"
              type="text"
              :label="$t('data-section-input-15')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.address_block"
              type="text"
              :label="$t('data-section-input-16')"
              :loading="cepLoading"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.address_note"
              type="text"
              :label="$t('data-section-input-17')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.address_city"
              type="text"
              :label="$t('data-section-input-18')"
              :loading="cepLoading"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.address_state"
              type="text"
              :label="$t('data-section-input-19')"
              :loading="cepLoading"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.address_country"
              type="text"
              :label="$t('data-section-input-20')"
              :loading="cepLoading"
            />
          </div>
        </section>
        <section
          id="bank"
          name="bank"
          :class="{ 'hide-mobile': currentMenuItem != 'bank' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon
                :icon="{ prefix: 'far', iconName: 'credit-card' }"
              />
            </div>
            <div class="section-name">{{ $t("data-section-4") }}</div>
          </div>
          <div class="fields">
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.bank_name"
              type="text"
              :label="$t('data-section-input-21')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.bank_code"
              type="text"
              :mask="'######'"
              :label="$t('data-section-input-22')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.bank_branch"
              type="text"
              :mask="[
                $root.$t('bank-agency-mask-1'),
                $root.$t('bank-agency-mask-2')
              ]"
              :label="$t('data-section-input-23')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.bank_account"
              type="text"
              mask="###################"
              :label="$t('data-section-input-24')"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              :label="$t('data-section-input-25')"
              type="select"
              v-model="user.bank_account_type"
              :items="[$t('bank-account-type-1'), $t('bank-account-type-2')]"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              :label="$t('data-section-input-26')"
              type="select"
              v-model="user.bank_account_owner"
              :items="[$t('bank-account-owner-1'), $t('bank-account-owner-2')]"
            />
            <we-input
              :readonly="
                loggedUser.role != 'admin' && loggedUser.role != 'manager'
              "
              v-model="user.bank_account_owner_document"
              type="text"
              :label="$t('data-section-input-27')"
              :mask="[$root.$t('cpf-mask'), $root.$t('cnpj-mask')]"
            />
          </div>
        </section>
        <section
          id="custom-fields"
          name="custom-fields"
          :class="{ 'hide-mobile': currentMenuItem != 'custom-fields' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="folder-plus" />
            </div>
            <div class="section-name">{{ $t("data-section-5") }}</div>
          </div>
          <div class="fields">
            <we-input
              v-for="(field, i) in customFieldsInputs"
              :key="i"
              :placeholder="field.placeholder"
              :type="field.type"
              :label="field.label"
              :items="field.values"
              :isRequired="field.is_required"
              :readonly="
                field.is_read_only &&
                  loggedUser.role != 'admin' &&
                  loggedUser.role != 'manager'
              "
              v-model="userCustomFields[field.name]"
              :mask="field.mask"
              :class="field.layout"
            />
          </div>
        </section>
      </v-form>
      <section
        id="attachments"
        name="attachments"
        :class="{ 'hide-mobile': currentMenuItem != 'attachments' }"
      >
        <div class="title">
          <div class="icon">
            <font-awesome-icon icon="paperclip" />
          </div>
          <div class="section-name">{{ $t("attachments-section") }}</div>
        </div>
        <div class="fields fill">
          <drop-file
            ref="dropFileComponent"
            entity="user"
            :recordId="user.id"
          />
        </div>
      </section>
      <section
        id="activities"
        name="activities"
        :class="{ 'hide-mobile': currentMenuItem != 'activities' }"
      >
        <div class="title">
          <div class="icon">
            <font-awesome-icon icon="tasks" />
          </div>
          <div class="section-name">{{ $t("activities-section") }}</div>
        </div>
        <div class="fields fill">
          <activities
            ref="activitiesComponent"
            entity="user"
            :recordId="user.id"
          />
        </div>
      </section>
    </template>
    <template #bottom>
      <div class="history">
        <div class="icon">
          <font-awesome-icon icon="history" />
        </div>
        <div class="data">
          <div class="item">
            {{ $t("created-at") }}:
            <span>{{ user.created_at | dateTime }}</span>
          </div>
          <div class="item">
            {{ $t("updated-at") }}:
            <span>{{ user.updated_at | dateTime }}</span>
          </div>
        </div>
      </div>
      <we-button
        @click="updateUser(user)"
        class="green mobile-icon"
        :text="$t('save')"
        :mobile-text="$t('save-mobile')"
        icon="check"
        :loading="btnLoading"
      />
    </template>
  </modal-entity>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      confirmDeleteOpened: false,
      createValid: false,
      loading: true,
      btnLoading: false,
      imgLoading: false,
      picture: {},
      dragovered: false,
      cepLoading: false,
      userActiveList: [
        {
          text: this.$t("user-active-1"),
          value: 1
        },
        {
          text: this.$t("user-active-2"),
          value: 0
        }
      ],
      labelIsActive: "",
      reloadUsers: false,
      currentMenuItem: "profile"
    };
  },
  computed: {
    menuItems() {
      return [
        {
          id: "profile",
          name: this.$t("data-section-1"),
          icon: "user"
        },
        {
          id: "permissions",
          name: this.$t("data-section-2"),
          icon: "key"
        },
        {
          id: "address",
          name: this.$t("data-section-3"),
          icon: "globe-americas"
        },
        {
          id: "bank",
          name: this.$t("data-section-4"),
          icon: ["far", "credit-card"]
        },
        {
          id: "custom-fields",
          name: this.$t("data-section-5"),
          icon: "folder-plus"
        },
        {
          id: "attachments",
          name: this.$t("attachments-section"),
          icon: "paperclip"
        },
        {
          id: "activities",
          name: this.$t("activities-section"),
          icon: "tasks"
        }
      ];
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    pipelinesList() {
      return this.pipelines.map(e => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    roleList() {
      return this.$store?.getters?.getUserRoleList;
    },
    user() {
      return this.$store?.getters?.getCurrentUser;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    customFieldsInputs() {
      return this.$store.getters.getUserCustomFieldsInputs;
    },
    userCustomFields() {
      return this.$store.getters.getUserCustomFields;
    },
    mapPosition() {
      return {
        lat: parseFloat(this.user.address_latitude),
        lng: parseFloat(this.user.address_longitude)
      };
    }
  },
  methods: {
    ...mapActions([
      "userRequest",
      "updateUserRequest",
      "deleteUserRequest",
      "updateUserPictureRequest",
      "deleteUserPictureRequest",
      "customFieldsRequest",
      "cepRequest",
      "pipelinesRequest"
    ]),
    changeRole(value) {
      if (
        !["restricted agent", "external agent", "agent", "visitor"].includes(
          value
        )
      ) {
        this.user.is_data_access_restricted = false;
      }
    },
    async updateUser(user) {
      this.btnLoading = true;
      user.custom_fields = [this.userCustomFields];
      this.reloadUsers = true;
      await this.updateUserRequest(user);
      this.btnLoading = false;
    },
    async deleteUser(id) {
      this.loading = true;
      this.reloadUsers = true;
      await this.deleteUserRequest(id);
      this.loading = false;
      this.closeUser();
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      this.picture = file;
      this.imgLoading = true;

      const payload = {
        picture: this.picture,
        id: this.user.id
      };
      this.reloadUsers = true;
      await this.updateUserPictureRequest(payload);
      this.imgLoading = false;
    },
    async deleteUserImage() {
      this.imgLoading = true;
      this.reloadUsers = true;
      await this.deleteUserPictureRequest(this.user.id);
      this.imgLoading = false;
    },
    async getCep(number) {
      if (number.length === 9) {
        this.cepLoading = true;
        await this.cepRequest(number.split("-").join(""));
        this.cepLoading = false;
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(e) {
      e.preventDefault();
      this.dragovered = true;
    },
    dragleave(e) {
      e.preventDefault();
      this.dragovered = false;
    },
    drop(event) {
      event.preventDefault();
      this.dragovered = false;
      this.$refs.dropFileComponent.onChange(event.dataTransfer.files);
    },
    closeUser() {
      this.$router.push({ name: "users", query: { reload: this.reloadUsers } });
    }
  },
  async mounted() {
    await this.userRequest(this.$route.params.userId);
    document.title = `${this.user.first_name} ${this.user.last_name}`;
    await this.customFieldsRequest();
    await this.pipelinesRequest();
    await this.$refs.activitiesComponent.allNotesRequest();
    await this.$refs.dropFileComponent.allFilesRequest();
    this.$refs.form.validate();
    this.loading = false;
  }
};
</script>

<style lang="scss"></style>
