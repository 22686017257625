<i18n>
{
  "en": {
    "title": "Users",
    "search": "Search for name, role or address...",
    "create": "New user",
    "create-mobile": "New",
    "filter": "Filter",
    "export-results": "Export results",
    "edit-filtered": "Edit filtered data",
    "delete-filtered": "Delete filtered data",
    "as-csv": "as CSV",
    "export-all": "Export all",
    "import": "Import",
    "not-found": "Users not found",
    "not-found-description": "Check your search or filter again or click on button below to create a new one",
    "column-1": "Name",
    "column-2": "Enabled",
    "column-3": "Role",
    "column-4": "Created at",
    "column-5": "Updated at"
  },
  "pt-BR": {
    "title": "Usuários",
    "search": "Pesquise nome, cargo, endereço...",
    "create": "Novo usuário",
    "create-mobile": "Novo",
    "filter": "Filtro",
    "export-results": "Exportar resultados",
    "edit-filtered": "Editar registros filtrados",
    "delete-filtered": "Deletar registros filtrados",
    "as-csv": "como CSV",
    "export-all": "Exportar tudo",
    "import": "Importar",
    "not-found": "Nenhum usuário encontrado",
    "not-found-description": "Verifique sua pesquisa novamente ou clique no botão abaixo para criar",
    "column-1": "Nome",
    "column-2": "Habilitado",
    "column-3": "Permissão",
    "column-4": "Data de cadastro",
    "column-5": "Última atualização"
  }
}
</i18n>

<template>
  <div id="users">
    <nav-top :title="$t('title')" :returnPage="!isDesktop ? 'records' : null">
      <template #top-content>
        <we-input
          type="search"
          v-model="search"
          @input="searchUsers"
          :inputLabel="$t('search')"
          cy="search-in-users"
        ></we-input>
        <we-button
          v-if="loggedUser.role === 'admin'"
          class="green mobile-icon"
          :text="$t('create')"
          :mobile-text="$t('create-mobile')"
          icon="user-plus"
          @click="$router.push({ name: 'createUser' })"
          cy="create-user-cta"
        />
      </template>
    </nav-top>
    <board-list class="users">
      <router-view />
      <template #filters>
        <we-pagination :pages="usersPages" @change="selectPage($event)" />
        <we-button
          class="mobile-icon"
          :class="filterActive ? 'primary' : 'disabled'"
          :text="$t('filter')"
          icon="sliders-h"
          @click="$router.push({ name: 'filterUser' })"
          cy="filter-in-users"
        />
        <we-balloon v-if="filterActive">
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export-results')"
              color="icon-primary"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFilteredFile('csv')">
              <font-awesome-icon icon="file-csv" />
              <div class="text">
                {{ $t("export-results") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          v-if="filterActive"
          :icon="['fas', 'edit']"
          :name="$t('edit-filtered')"
          @click="massUpdateOpened = true"
          cy="update-filtered-users"
        />
        <mass-update
          :open="massUpdateOpened"
          @close="massUpdateOpened = false"
          @confirm="doRequests"
          :total-records="usersPages.total"
        />
        <we-icon-button
          v-if="filterActive"
          :icon="['far', 'trash-alt']"
          :name="$t('delete-filtered')"
          color="icon-red"
          @click="confirmDeleteOpened = true"
          cy="delete-filtered-users"
        />
        <confirm-modal
          :open="confirmDeleteOpened"
          :total-records="usersPages.total"
          @confirm="deleteFilteredData()"
          @close="confirmDeleteOpened = false"
        />
      </template>
      <template #actions>
        <we-icon-button
          icon="sync-alt"
          @click="doRequests(true)"
          name="Recarregar"
          cy="reload-users"
        />
        <we-balloon>
          <template #action>
            <we-icon-button
              icon="download"
              name="Exportar"
              cy="export-users-trigger"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFile">
              <font-awesome-icon icon="file-csv" />
              <div class="text" cy="export-users">
                {{ $t("export-all") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          icon="file-upload"
          @click="openImport"
          :name="$t('import')"
          cy="import-users"
        />
      </template>
      <template #list>
        <we-loading-overflow :loading="loadingUsers" />
        <div class="labels">
          <div
            class="label"
            :class="
              elem.orderBy === usersSort.orderBy ? usersSort.direction : ''
            "
            v-for="elem in sortElements"
            :key="elem.orderBy"
            @click="orderBy(elem)"
          >
            <we-text-tooltip class="name" :text="elem.name" lines="1" />
            <div class="icon">
              <font-awesome-icon icon="sort-up" />
              <font-awesome-icon icon="sort-down" />
            </div>
          </div>
        </div>
        <div class="items">
          <we-not-found v-if="users.length === 0">
            <template #title> {{ $t("not-found") }} </template>
            <template #description>
              {{ $t("not-found-description") }}
            </template>
            <template #actions>
              <we-button
                v-if="loggedUser.role === 'admin'"
                class="green"
                cy="create-user-cta"
                :text="$t('create')"
                icon="user-plus"
                @click="$router.push({ name: 'createUser' })"
              />
            </template>
          </we-not-found>
          <div
            class="card-list"
            v-for="(user, i) in users"
            :key="i"
            @click="openUser(user.id)"
          >
            <div class="column profile">
              <we-rounded-picture
                :picture="user.picture"
                :name="user.first_name"
                :text="
                  $options.filters.firstLetters(
                    `${user.first_name} ${user.last_name}`
                  )
                "
                size="36"
              />
              <div class="data">
                <we-text-tooltip
                  class="name"
                  :text="`${user.first_name} ${user.last_name}`"
                  lines="1"
                />
                <we-text-tooltip class="email" :text="user.email" lines="1" />
              </div>
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.booleanToString(user.is_active)"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.convertRole(user.role)"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(user.created_at)"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(user.updated_at)"
                lines="1"
              />
            </div>
          </div>
        </div>
      </template>
    </board-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      massUpdateOpened: false,
      confirmDeleteOpened: false,
      createValid: false,
      search: "",
      searching: false,
      loadingBtn: false,
      loadingUsers: true,
      initialPage: 1,
      paginationStorage: "users-page",
      sortElements: [
        {
          name: this.$t("column-1"),
          orderBy: "first_name",
          direction: "desc"
        },
        {
          name: this.$t("column-2"),
          orderBy: "is_active",
          direction: "desc"
        },
        {
          name: this.$t("column-3"),
          orderBy: "role",
          direction: "desc"
        },
        {
          name: this.$t("column-4"),
          orderBy: "created_at",
          direction: "desc"
        },
        {
          name: this.$t("column-5"),
          orderBy: "updated_at",
          direction: "desc"
        }
      ]
    };
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    usersPages() {
      return this.$store.getters.getUsersPages;
    },
    usersSort() {
      return this.$store.getters.getUsersSort;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    }
  },
  methods: {
    ...mapActions([
      "usersRequest",
      "createUserRequest",
      "searchUsersRequest",
      "filterUsersRequest",
      "exportRequest",
      "exportFilteredAsCsvRequest",
      "deleteFilteredRecords"
    ]),
    async deleteFilteredData() {
      this.loadingUsers = true;
      await this.deleteFilteredRecords();
      await this.doRequests();
    },
    exportFile() {
      const payload = {
        entity: "users"
      };

      this.exportRequest(payload);
    },
    exportFilteredFile(ext) {
      if (ext === "csv") {
        this.exportFilteredAsCsvRequest("users-filtered");
      }
    },
    openImport() {
      this.$router.push({ name: "importUsers" });
    },
    searchUsers(val) {
      this.loadingUsers = true;
      this.$store.commit("setFilterActive", false);
      let orderBy = {};
      if (this.usersSort.orderBy) {
        orderBy = {
          orderBy: this.usersSort.orderBy,
          direction: this.usersSort.direction
        };
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (val?.length > 0) {
          await this.searchUsersRequest({
            value: this.search,
            page: 1,
            ...orderBy
          });
          this.searching = true;
        } else {
          await this.doRequests();
        }
        this.loadingUsers = false;
      }, 600);
    },
    openUser(id) {
      this.$router.push({ name: "user", params: { userId: id } });
    },
    async orderBy(elem) {
      this.loadingUsers = true;

      if (elem.direction === "desc") {
        elem.direction = "asc";
      } else {
        elem.direction = "desc";
      }

      this.$store.commit("setUsersSort", elem);

      let orderBy = {};
      if (elem.orderBy) {
        orderBy = {
          orderBy: elem.orderBy,
          direction: elem.direction
        };
      }

      if (this.filterActive) {
        await this.filterUsersRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.usersPages.current_page,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchUsersRequest({
          value: this.search,
          page: this.usersPages.current_page,
          paginate: true,
          ...orderBy
        });
      } else {
        await this.usersRequest({
          page: this.usersPages.current_page,
          updateLocal: false,
          ...orderBy
        });
      }
      this.loadingUsers = false;
    },
    async doRequests(reset) {
      this.loadingUsers = true;
      this.searching = false;

      if (reset) {
        this.usersPages.current_page = this.initialPage;
        this.$store.commit("setFilterActive", false);
        this.$store.commit("setUsersSort", {
          orderBy: "",
          direction: "",
          name: ""
        });
      }

      if (this.filterActive) {
        await this.filterUsersRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.usersPages.current_page
        });
      } else if (this.search.length > 0) {
        await this.searchUsersRequest({
          value: this.search,
          page: this.usersPages.current_page,
          paginate: true
        });
      } else {
        await this.usersRequest({ page: this.usersPages.current_page });
      }

      this.loadingUsers = false;
    },
    async selectPage(val) {
      this.loadingUsers = true;
      let orderBy = {};
      if (this.usersSort.orderBy) {
        orderBy = {
          orderBy: this.usersSort.orderBy,
          direction: this.usersSort.direction
        };
      }

      if (this.filterActive) {
        await this.filterUsersRequest({
          filters: this.exportParams?.payload?.filters,
          page: val,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchUsersRequest({
          value: this.search,
          page: val,
          ...orderBy
        });
      } else {
        await this.usersRequest({
          page: val,
          ...orderBy,
          updateLocal: false
        });
      }

      this.loadingUsers = false;
    }
  },
  async mounted() {
    await this.doRequests(true);
    this.$store.commit("resetFilters");
  },
  beforeRouteUpdate(to, from, next) {
    if (to.hash.length > 0) {
      return;
    }
    if (from.name === "filterUser") {
      this.search = "";
    }
    if (from.name === "importUsers") {
      this.doRequests();
    }
    if (to.query.reload) {
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#users {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
</style>
