<i18n>
{
  "en": {
    "title": "New user",
    "input-1": "Name",
    "input-2": "Surname",
    "input-3": "Email",
    "input-4": "User type",
    "input-5": "Password",
    "placeholder-1": "Enter a name",
    "placeholder-2": "Enter a surname",
    "placeholder-3": "Enter an email",
    "placeholder-4": "Select a type",
    "placeholder-5": "Enter a password",
    "create": "Create user",
    "role-1": "Administrator",
    "role-2": "Agent",
    "role-3": "Restricted agent",
    "role-4": "External agent",
    "role-5": "Manager",
    "role-6": "Visitor"
  },
  "pt-BR": {
    "title": "Novo Usuário",
    "input-1": "Nome",
    "input-2": "Sobrenome",
    "input-3": "Email",
    "input-4": "Tipo de usuário",
    "input-5": "Senha",
    "placeholder-1": "Digite um nome",
    "placeholder-2": "Digite um sobrenome",
    "placeholder-3": "Digite um email",
    "placeholder-4": "Selecione um tipo",
    "placeholder-5": "Digite uma senha",
    "create": "Criar usuário",
    "role-1": "Administrador",
    "role-2": "Agente",
    "role-3": "Agente restrito",
    "role-4": "Agente externo",
    "role-5": "Gerente",
    "role-6": "Visitante"
  }
}
</i18n>

<template>
  <modal-action @close="closeCreateUser" :columns="2">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="user-plus" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeCreateUser" />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="form" v-model="createValid">
            <div class="fields">
              <we-input
                v-model="first_name"
                cy="create-user-first-name"
                isRequired
                type="text"
                :label="$t('input-1')"
                :placeholder="$t('placeholder-1')"
              />
              <we-input
                v-model="last_name"
                cy="create-user-last-name"
                isRequired
                type="text"
                :label="$t('input-2')"
                :placeholder="$t('placeholder-2')"
              />
              <we-input
                class="double-column"
                v-model="email"
                cy="create-user-email"
                isRequired
                type="email"
                :label="$t('input-3')"
                :placeholder="$t('placeholder-3')"
              />
              <we-input
                v-model="role"
                cy="create-user-role"
                isRequired
                type="global-select"
                :items="roleList"
                :clearable="false"
                :label="$t('input-4')"
                :placeholder="$t('placeholder-4')"
              />
              <we-input
                v-model="password"
                cy="create-user-password"
                isRequired
                type="password"
                :label="$t('input-5')"
                :placeholder="$t('placeholder-5')"
              />
              <we-input
                v-for="(field, i) in customFieldsRequired"
                :class="field.layout"
                :key="i"
                :type="field.type"
                :label="field.label"
                :items="field.values"
                :isRequired="field.is_required"
                :readonly="field.is_read_only"
                :placeholder="field.placeholder"
                v-model="userCustomFields[field.name]"
                :mask="field.mask"
              />
            </div>
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            cy="create-user"
            :text="$t('create')"
            icon="plus"
            :loading="loadingBtn"
            @click="createUser"
            :disabled="!createValid"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      createValid: false,
      loadingBtn: false,
      loading: true,
      first_name: "",
      last_name: "",
      email: "",
      role: "",
      roleList: [
        {
          text: this.$t("role-1"),
          value: "admin"
        },
        {
          text: this.$t("role-2"),
          value: "agent"
        },
        {
          text: this.$t("role-3"),
          value: "restricted agent"
        },
        {
          text: this.$t("role-4"),
          value: "external agent"
        },
        {
          text: this.$t("role-5"),
          value: "manager"
        },
        {
          text: this.$t("role-6"),
          value: "visitor"
        }
      ],
      password: ""
    };
  },
  computed: {
    customFieldsInputs() {
      return this.$store.getters.getUserCustomFieldsInputs;
    },
    customFieldsRequired() {
      return this.customFieldsInputs.filter(e => e.is_required);
    },
    userCustomFields() {
      return this.$store.getters.getUserCustomFields;
    }
  },
  methods: {
    ...mapActions(["createUserRequest", "customFieldsRequest"]),
    closeCreateUser() {
      this.$router.push({ name: "users" });
    },
    async createUser() {
      this.loadingBtn = true;
      let formData = new FormData();

      const payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        role: this.role,
        password: this.password,
        ...this.userCustomFields
      };

      for (var e in payload) {
        formData.append(e, payload[e]);
      }

      const response = await this.createUserRequest(formData);

      if (response?.data?.id) {
        this.$router.push({
          name: "user",
          params: { userId: response.data.id },
          query: { reload: true }
        });
      }
      this.loadingBtn = false;
    }
  },
  async mounted() {
    this.$store.commit("clearUserCustomFieldsValues");
    await this.customFieldsRequest();
    this.loading = false;
  }
};
</script>
