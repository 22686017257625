<i18n>
{
  "en": {
    "delete-title": "Delete bill",
    "data-section": "Bill",
    "data-section-input-1": "Description",
    "data-section-input-2": "Code",
    "data-section-input-3": "Status",
    "data-section-input-4": "Method",
    "data-section-input-5": "Type",
    "data-section-input-6": "Shipping",
    "data-section-input-7": "Subtotal",
    "data-section-input-8": "Total",
    "data-section-input-9": "Bill url",
    "data-section-input-10": "Emission data",
    "data-section-input-11": "Due data",
    "data-section-input-12": "Payment data",
    "data-section-input-13": "Responsible user",
    "data-section-input-14": "Related card",
    "data-section-input-15": "Related contact",
    "data-section-input-16": "Related company",
    "data-section-input-17": "Cost center",
    "data-section-input-18": "Tags",
    "custom-fields-title": "Custom Fields",
    "attachments-section": "Attachments",
    "activities-section": "Activities",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "save": "Save updates",
    "save-mobile": "Save"
  },
  "pt-BR": {
    "delete-title": "Deletar lançamento",
    "data-section": "Lançamento",
    "data-section-input-1": "Descrição",
    "data-section-input-2": "Código",
    "data-section-input-3": "Status",
    "data-section-input-4": "Método",
    "data-section-input-5": "Tipo",
    "data-section-input-6": "Valor do frete",
    "data-section-input-7": "Subtotal",
    "data-section-input-8": "Total",
    "data-section-input-9": "Link do lançamento",
    "data-section-input-10": "Data de emissão",
    "data-section-input-11": "Data de vencimento",
    "data-section-input-12": "Data do pagamento",
    "data-section-input-13": "Usuário responsável",
    "data-section-input-14": "Card relacionado",
    "data-section-input-15": "Contato relacionado",
    "data-section-input-16": "Empresa relacionada",
    "data-section-input-17": "Centro de custo",
    "data-section-input-18": "Etiquetas",
    "custom-fields-title": "Campos Customizados",
    "attachments-section": "Anexos",
    "activities-section": "Atividades",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "save": "Salvar alterações",
    "save-mobile": "Salvar"
  }
}
</i18n>

<template>
  <modal-entity
    class="bill"
    @close="closeBill"
    @drop="drop"
    :menuItems="menuItems"
    @currentMenuItem="currentMenuItem = $event"
    :loading="loading"
  >
    <template #actions>
      <confirm-modal
        :open="confirmDeleteOpened"
        @close="confirmDeleteOpened = false"
        @confirm="deleteBill(bill.id)"
      />
      <we-icon-button
        :icon="['far', 'trash-alt']"
        :name="$t('delete-title')"
        @click="confirmDeleteOpened = true"
        direction="bottom"
        class="big-mobile"
        cy="delete-bill"
      />
      <we-icon-button class="big-mobile" icon="times" @click="closeBill" />
    </template>
    <template #data>
      <div class="data-grid small">
        <div class="entity-icon">
          <font-awesome-icon icon="file-invoice-dollar" />
        </div>
        <div class="data-info">
          <we-text-tooltip
            class="name"
            :text="`${bill.description}`"
            lines="1"
          />
          <div class="data-list">
            <span
              v-if="bill.total > 0"
              class="price"
              :class="{
                'success--text': bill.type === 'revenue',
                'red--text': bill.type === 'expense'
              }"
            >
              {{ bill.total | valueToReais }}
            </span>
            <span v-if="bill.type.length > 0" class="only-desktop">
              {{ bill.type | billTypesToPtBr }}
            </span>
            <span v-if="bill.status.length > 0">
              <we-label :class="billColors[bill.status]" class="lighten-1">
                <template #text>
                  <we-text-tooltip
                    :text="$options.filters.billStatusToPtBr(bill.status)"
                  />
                </template>
              </we-label>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <v-form ref="form">
        <section
          id="profile"
          name="profile"
          :class="{ 'hide-mobile': currentMenuItem != 'profile' }"
          v-if="!loading"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="file-invoice-dollar" />
            </div>
            <div class="section-name">
              {{ $t("data-section") }}
            </div>
          </div>
          <div class="fields">
            <we-input
              class="fill-input"
              v-model="bill.description"
              type="text"
              :label="$t('data-section-input-1')"
            />
            <we-input
              v-model="bill.tags"
              type="tags"
              :items="bill.tags"
              :label="$t('data-section-input-18')"
            />
            <we-input
              v-model="bill.code"
              type="text"
              :label="$t('data-section-input-2')"
            />
            <we-input
              v-model="bill.status"
              type="global-select"
              :label="$t('data-section-input-3')"
              :items="billStatusList"
            />
            <we-input
              v-model="bill.method"
              type="global-select"
              :label="$t('data-section-input-4')"
              :items="billMethodsList"
            />
            <we-input
              v-model="bill.type"
              type="global-select"
              :label="$t('data-section-input-5')"
              :items="billTypesList"
            />
            <we-input
              v-model="bill.shipping_cost"
              type="money"
              :label="$t('data-section-input-6')"
            />
            <we-input
              v-model="bill.subtotal"
              type="money"
              :label="$t('data-section-input-7')"
            />
            <we-input
              v-model="bill.total"
              type="money"
              :label="$t('data-section-input-8')"
            />
            <we-input
              class="fill-input"
              v-model="bill.url"
              type="text"
              :label="$t('data-section-input-9')"
            />
            <we-input
              v-model="bill.issue_date"
              type="date"
              :clearable="true"
              :label="$t('data-section-input-10')"
            />
            <we-input
              v-model="bill.due_date"
              type="date"
              :clearable="true"
              :label="$t('data-section-input-11')"
            />
            <we-input
              v-model="bill.paid_at"
              type="date"
              :clearable="true"
              :label="$t('data-section-input-12')"
            />
            <we-input-user
              :label="$t('data-section-input-13')"
              :value="bill.user_id"
              @input="bill.user_id = $event.id"
            />
            <we-input-deals
              :label="$t('data-section-input-14')"
              :value="bill.deal_id"
              @input="bill.deal_id = $event.id"
            />
            <we-input-contacts
              :label="$t('data-section-input-15')"
              :value="bill.contact_id"
              @input="bill.contact_id = $event.id"
            />
            <we-input-companies
              :label="$t('data-section-input-16')"
              :value="bill.company_id"
              @input="bill.company_id = $event.id"
            />
            <we-input
              v-model="bill.cost_center"
              type="tag"
              :label="$t('data-section-input-17')"
              :items="costCentersList"
            />
          </div>
        </section>
        <section
          id="custom-fields"
          name="custom-fields"
          :class="{ 'hide-mobile': currentMenuItem != 'custom-fields' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="folder-plus" />
            </div>
            <div class="section-name">
              {{ $t("custom-fields-title") }}
            </div>
          </div>
          <div class="fields">
            <we-input
              v-for="(field, i) in customFieldsInputs"
              :class="field.layout"
              :key="i"
              :placeholder="field.placeholder"
              :type="field.type"
              :label="field.label"
              :items="field.values"
              :isRequired="field.is_required"
              :readonly="field.is_read_only"
              v-model="billCustomFields[field.name]"
              :mask="field.mask"
            />
          </div>
        </section>
      </v-form>
      <section
        id="attachments"
        name="attachments"
        :class="{ 'hide-mobile': currentMenuItem != 'attachments' }"
      >
        <div class="title">
          <div class="icon">
            <font-awesome-icon icon="paperclip" />
          </div>
          <div class="section-name">
            {{ $t("attachments-section") }}
          </div>
        </div>
        <div class="fields fill">
          <drop-file
            ref="dropFileComponent"
            entity="bill"
            :recordId="bill.id"
          />
        </div>
      </section>
      <section
        id="activities"
        name="activities"
        :class="{ 'hide-mobile': currentMenuItem != 'activities' }"
      >
        <div class="title">
          <div class="icon">
            <font-awesome-icon icon="tasks" />
          </div>
          <div class="section-name">
            {{ $t("activities-section") }}
          </div>
        </div>
        <div class="fields fill">
          <activities
            ref="activitiesComponent"
            entity="bill"
            :recordId="bill.id"
          />
        </div>
      </section>
    </template>
    <template #bottom>
      <div class="history">
        <div class="icon">
          <font-awesome-icon icon="history" />
        </div>
        <div class="data">
          <div class="item">
            {{ $t("created-at") }}:
            <span>{{ bill.created_at | dateTime }}</span>
          </div>
          <div class="item">
            {{ $t("updated-at") }}:
            <span>{{ bill.updated_at | dateTime }}</span>
          </div>
        </div>
      </div>
      <we-button
        @click="updateBill(bill)"
        class="green mobile-icon"
        :text="$t('save')"
        :mobile-text="$t('save-mobile')"
        icon="check"
        :loading="btnLoading"
      />
    </template>
  </modal-entity>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      confirmDeleteOpened: false,
      loading: true,
      btnLoading: false,
      searchLoading: false,
      firstEvent: true,
      debounce: null,
      startDate: "",
      reloadBills: false,
      currentMenuItem: "profile"
    };
  },
  computed: {
    menuItems() {
      return [
        {
          id: "profile",
          name: this.$t("data-section"),
          icon: "file-invoice-dollar"
        },
        {
          id: "custom-fields",
          name: this.$t("custom-fields-title"),
          icon: "folder-plus"
        },
        {
          id: "attachments",
          name: this.$t("attachments-section"),
          icon: "paperclip"
        },
        {
          id: "activities",
          name: this.$t("activities-section"),
          icon: "tasks"
        }
      ];
    },
    customFieldsInputs() {
      return this.$store.getters.getCustomFields;
    },
    billCustomFields() {
      return this.$store.getters.getBillCustomFields;
    },
    costCenters() {
      return this.$store.getters.getCostCenters;
    },
    costCentersList() {
      if (!this.bill.cost_center) return this.$store.getters.getCostCenters;

      return [this.bill.cost_center, ...this.$store.getters.getCostCenters];
    },
    billColors() {
      return this.$store.getters.getBillColors;
    },
    bill() {
      return this.$store.getters.getCurrentBill;
    },
    billMethodsList() {
      return this.$store.getters.getBillMethodsList;
    },
    billStatusList() {
      return this.$store.getters.getBillStatusList;
    },
    billTypesList() {
      return this.$store.getters.getBillTypesList;
    },
    dealId() {
      return this.bill?.deal_id;
    },
    contactId() {
      return this.bill?.contact_id;
    },
    companyId() {
      return this.bill?.company_id;
    }
  },
  methods: {
    ...mapActions([
      "billRequest",
      "updateBillRequest",
      "deleteBillRequest",
      "billsRequest",
      "userRequest",
      "dealRequest",
      "contactRequest",
      "companyRequest",
      "costCentersRequest",
      "customFieldsRequest"
    ]),
    drop(event) {
      this.$refs.dropFileComponent.onChange(event.dataTransfer.files);
    },
    async updateBill(bill) {
      this.btnLoading = true;

      let payload = { ...bill };
      payload.custom_fields = [this.billCustomFields];

      this.reloadBills = true;
      await this.updateBillRequest({
        ...payload
      });
      this.costCentersRequest();
      await this.billsRequest();
      this.btnLoading = false;
    },
    async deleteBill(id) {
      this.loading = true;
      this.reloadBills = true;
      await this.deleteBillRequest(id);
      this.loading = false;
      this.closeBill();
    },
    closeBill() {
      this.$router.push({ name: "bills", query: { reload: this.reloadBills } });
    },
    async doRequests() {
      this.loading = true;
      await this.billRequest(this.$route.params.billId);

      document.title = this.bill.description;

      await Promise.all([
        this.$refs.activitiesComponent.allNotesRequest(),
        this.$refs.dropFileComponent.allFilesRequest(),
        this.customFieldsRequest({
          entity: "bill"
        })
      ]);

      if (this.costCenters.length <= 0) {
        await this.costCentersRequest();
      }
      this.loading = false;

      setTimeout(() => {
        this.$refs.form.validate();
      }, 100);
    }
  },
  async mounted() {
    await this.doRequests();
  }
};
</script>

<style lang="scss"></style>
