<i18n>
{
  "en": {
    "title": "Financial",
    "balance": "Balance",
    "balance-tooltip-1": "Calculation:",
    "balance-tooltip-2": "revenues - expenses",
    "balance-tooltip-3": "If you search or filter the balance will update.",
    "search": "Search for description, type or status",
    "create": "New bill",
    "create-mobile": "New",
    "filter": "Filter",
    "export-results": "Export results",
    "edit-filtered": "Edit filtered data",
    "delete-filtered": "Delete filtered data",
    "as-csv": "as CSV",
    "reload": "Reload",
    "export": "Export",
    "export-all": "Export all",
    "import": "Import",
    "not-found": "Bills not found",
    "not-found-description": "Check your search or filter again or click on button below to create a new one",
    "column-1": "Description",
    "column-2": "Type",
    "column-3": "Total",
    "column-4": "Status",
    "column-5": "Issue date",
    "column-6": "Paid date",
    "column-7": "Due date",
    "nested-menu-1": "Duplicate",
    "nested-menu-2": "Update status",
    "nested-menu-3": "Delete"
  },
  "pt-BR": {
    "title": "Financeiro",
    "balance": "Saldo",
    "balance-tooltip-1": "Cálculo realizado:",
    "balance-tooltip-2": "total de contas a receber - total de contas a pagar",
    "balance-tooltip-3": "Caso você realize um filtro ou uma pesquisa o saldo será atualizado.",
    "search": "Pesquise pela descrição, tipo, status...",
    "create": "Novo lançamento",
    "create-mobile": "Novo",
    "filter": "Filtro",
    "export-results": "Exportar resultados",
    "edit-filtered": "Editar registros filtrados",
    "delete-filtered": "Deletar registros filtrados",
    "as-csv": "como CSV",
    "reload": "Recarregar",
    "export": "Exportar",
    "export-all": "Exportar tudo",
    "import": "Importar",
    "not-found": "Nenhum lançamento encontrado",
    "not-found-description": "Verifique sua pesquisa novamente ou clique no botão abaixo para criar",
    "column-1": "Descrição",
    "column-2": "Tipo",
    "column-3": "Total",
    "column-4": "Status",
    "column-5": "Emissão",
    "column-6": "Pagamento",
    "column-7": "Vencimento",
    "nested-menu-1": "Duplicar",
    "nested-menu-2": "Atualizar status",
    "nested-menu-3": "Deletar"
  }
}
</i18n>

<template>
  <div id="bills">
    <nav-top :title="$t('title')" :returnPage="!isDesktop ? 'records' : null">
      <template #top-content>
        <div class="balance">
          <div class="balance-label">
            {{ $t("balance") }}:
            <we-icon-tooltip
              direction="bottom"
              :icon="['far', 'question-circle']"
            >
              <template #text>
                <div class="balance-question">
                  <div class="we-label">
                    {{ $t("balance-tooltip-1") }}
                  </div>
                  <div class="calc">
                    {{ $t("balance-tooltip-2") }}
                  </div>
                  <div class="obs">
                    {{ $t("balance-tooltip-3") }}
                  </div>
                </div>
              </template>
            </we-icon-tooltip>
          </div>
          <div
            class="balance-value"
            :class="{ negative: billsPages.balance < 0 }"
          >
            {{ billsPages.balance | valueToReais }}
          </div>
        </div>
        <we-input
          type="search"
          v-model="search"
          @input="searchBills"
          :inputLabel="$t('search')"
          cy="search-in-bills"
        ></we-input>
        <we-button
          v-if="loggedUser.role === 'admin'"
          class="green mobile-icon header-action"
          :text="$t('create')"
          :mobile-text="$t('create-mobile')"
          icon="file-invoice-dollar"
          @click="$router.push({ name: 'createBill' })"
          cy="create-bill-cta"
        />
      </template>
    </nav-top>
    <board-list class="bills">
      <router-view />
      <template #filters>
        <we-pagination :pages="billsPages" @change="selectPage($event)" />
        <we-button
          class="mobile-icon"
          :class="filterActive ? 'primary' : 'disabled'"
          :text="$t('filter')"
          icon="sliders-h"
          @click="$router.push({ name: 'filterBill' })"
          cy="filter-in-bills"
        />
        <we-balloon v-if="filterActive">
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export-results')"
              color="icon-primary"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFilteredFile('csv')">
              <font-awesome-icon icon="file-csv" />
              <div class="text">
                {{ $t("export-results") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          v-if="filterActive"
          :icon="['fas', 'edit']"
          :name="$t('edit-filtered')"
          @click="massUpdateOpened = true"
          cy="update-filtered-bills"
        />
        <mass-update
          :open="massUpdateOpened"
          @close="massUpdateOpened = false"
          :total-records="billsPages.total"
          @confirm="doRequests"
        />
        <we-icon-button
          v-if="filterActive"
          :icon="['far', 'trash-alt']"
          :name="$t('delete-filtered')"
          color="icon-red"
          @click="confirmDeleteOpened = true"
        />
        <confirm-modal
          :open="confirmDeleteOpened"
          :total-records="billsPages.total"
          @confirm="deleteFilteredData()"
          @close="confirmDeleteOpened = false"
        />
      </template>
      <template #actions>
        <we-icon-button
          icon="sync-alt"
          @click="doRequests(true)"
          :name="$t('reload')"
          cy="reload-bills"
        />
        <we-balloon>
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export')"
              cy="export-bills-trigger"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFile('csv')">
              <font-awesome-icon icon="file-csv" />
              <div class="text" cy="export-bills">
                {{ $t("export-all") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          icon="file-upload"
          @click="openImport"
          :name="$t('import')"
        />
      </template>
      <template #list>
        <we-loading-overflow :loading="loadingBills" />
        <div class="labels">
          <div
            class="label"
            :class="
              elem.orderBy === billsSort.orderBy ? billsSort.direction : ''
            "
            v-for="elem in sortElements"
            :key="elem.orderBy"
            @click="orderBy(elem)"
          >
            <we-text-tooltip class="name" :text="elem.name" lines="1" />
            <div class="icon">
              <font-awesome-icon icon="sort-up" />
              <font-awesome-icon icon="sort-down" />
            </div>
          </div>
        </div>
        <div class="items">
          <we-not-found v-if="bills.length === 0">
            <template #title> {{ $t("not-found") }} </template>
            <template #description>
              {{ $t("not-found-description") }}
            </template>
            <template #actions>
              <we-button
                v-if="loggedUser.role === 'admin'"
                class="green"
                :text="$t('create')"
                icon="file-invoice-dollar"
                @click="$router.push({ name: 'createBill' })"
              />
            </template>
          </we-not-found>
          <v-card
            class="card-list we-card"
            v-for="(bill, i) in bills"
            :key="i"
            @click="openBill(bill.id)"
            @contextmenu="showOptions({ event: $event, bill })"
          >
            <div class="column">
              <we-text-tooltip
                class="name"
                :text="bill.description"
                lines="1"
              />
              <we-icon-tooltip
                v-if="bill.tags && bill.tags.length > 0"
                icon="tags"
              >
                <template #text>
                  <ul class="tooltip-list">
                    <li v-for="(tag, i) in bill.tags" :key="i">
                      {{ tag }}
                    </li>
                  </ul>
                </template>
              </we-icon-tooltip>
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.billTypesToPtBr(bill.type)"
                lines="1"
              />
            </div>
            <div
              class="column price"
              :class="{ 'success--text': bill.type === 'revenue' }"
            >
              <we-text-tooltip
                class="value"
                :text="$options.filters.valueToReais(bill.total)"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-label :class="billColors[bill.status]" class="lighten-1">
                <template #text>
                  <we-text-tooltip
                    class="name"
                    :text="$options.filters.billStatusToPtBr(bill.status)"
                  />
                </template>
              </we-label>
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                v-if="bill.issue_date"
                class="name"
                :text="$options.filters.date(bill.issue_date)"
                lines="1"
              />
              <we-text-tooltip v-else class="name" text="-" lines="1" />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                v-if="bill.paid_at"
                class="name"
                :text="$options.filters.date(bill.paid_at)"
                lines="1"
              />
              <we-text-tooltip v-else class="name" text="-" lines="1" />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                v-if="bill.due_date"
                class="name"
                :text="$options.filters.date(bill.due_date)"
                lines="1"
              />
              <we-text-tooltip v-else class="name" text="-" lines="1" />
            </div>
          </v-card>
          <we-nested-menu
            name="New Menu"
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            :absolute="true"
            :offset-x="true"
            :menu-items="billActionsMenu"
            @we-nested-menu-click="onMenuItemClick"
          />
        </div>
      </template>
    </board-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      massUpdateOpened: false,
      confirmDeleteOpened: false,
      createValid: false,
      search: "",
      loadingBtn: false,
      loadingBills: true,
      initialPage: 1,
      paginationStorage: "bills-page",
      sortElements: [
        {
          name: this.$t("column-1"),
          orderBy: "description",
          direction: "desc"
        },
        {
          name: this.$t("column-2"),
          orderBy: "type",
          direction: "desc"
        },
        {
          name: this.$t("column-3"),
          orderBy: "total",
          direction: "desc"
        },
        {
          name: this.$t("column-4"),
          orderBy: "status",
          direction: "desc"
        },
        {
          name: this.$t("column-5"),
          orderBy: "issue_date",
          direction: "desc"
        },
        {
          name: this.$t("column-6"),
          orderBy: "paid_at",
          direction: "desc"
        },
        {
          name: this.$t("column-7"),
          orderBy: "due_date",
          direction: "desc"
        }
      ],
      showMenu: false,
      selectedBill: {},
      x: 0,
      y: 0
    };
  },
  computed: {
    billColors() {
      return this.$store.getters.getBillColors;
    },
    billStatusList() {
      return this.$store.getters.getBillStatusList;
    },
    billStatusMenuList() {
      return this.billStatusList.map(e => {
        return {
          name: e.text,
          action: () => {
            this.updateBillStatus(e.value);
          }
        };
      });
    },
    billActionsMenu() {
      return [
        {
          name: this.$t("nested-menu-1"),
          icon: ["far", "copy"],
          action: () => {
            this.duplicateBill();
          }
        },
        {
          name: this.$t("nested-menu-2"),
          icon: "file-invoice-dollar",
          menu: this.billStatusMenuList
        },
        {
          name: this.$t("nested-menu-3"),
          icon: ["far", "trash-alt"],
          class: "red--text",
          action: () => {
            this.deleteBill();
          }
        }
      ];
    },
    bills() {
      return this.$store.getters.getBills;
    },
    billsPages() {
      return this.$store.getters.getBillsPages;
    },
    billsSort() {
      return this.$store.getters.getBillsSort;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    }
  },
  methods: {
    ...mapActions([
      "billsRequest",
      "createBillRequest",
      "deleteBillRequest",
      "exportRequest",
      "exportFilteredAsCsvRequest",
      "usersRequest",
      "searchBillsRequest",
      "filterBillsRequest",
      "updateBillRequest",
      "deleteFilteredRecords"
    ]),
    async deleteFilteredData() {
      this.loadingBills = true;
      await this.deleteFilteredRecords();
      await this.doRequests();
    },
    onMenuItemClick(item) {
      if (item.action) {
        item.action();
      }
    },
    async duplicateBill() {
      await this.createBillRequest(this.selectedBill);

      this.doRequests();
    },
    async deleteBill() {
      await this.deleteBillRequest(this.selectedBill.id);

      this.doRequests();
    },
    async updateBillStatus(status) {
      this.selectedBill.status = status;

      this.$store.commit("setCurrentBill", this.selectedBill);
      await this.updateBillRequest(this.selectedBill);

      this.doRequests();
    },
    showOptions({ event, bill }) {
      this.selectedBill = { ...bill };

      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    openImport() {
      this.$router.push({ name: "importBills" });
    },
    exportFile(ext) {
      const payload = {
        entity: "bills",
        format: ext
      };

      this.exportRequest(payload);
    },
    exportFilteredFile(ext) {
      if (ext === "csv") {
        this.exportFilteredAsCsvRequest("bills-filtered");
      }
    },
    searchBills(val) {
      this.loadingBills = true;
      this.$store.commit("setFilterActive", false);
      let orderBy = {};
      if (this.billsSort.orderBy) {
        orderBy = {
          orderBy: this.billsSort.orderBy,
          direction: this.billsSort.direction
        };
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (val?.length > 0) {
          await this.searchBillsRequest({
            value: this.search,
            page: 1,
            ...orderBy
          });
        } else {
          await this.doRequests();
        }
        this.loadingBills = false;
      }, 600);
    },
    openBill(id) {
      this.$router.push({ name: "bill", params: { billId: id } });
    },
    async orderBy(elem) {
      this.loadingBills = true;

      if (elem.direction === "desc") {
        elem.direction = "asc";
      } else {
        elem.direction = "desc";
      }

      this.$store.commit("setBillsSort", elem);

      let orderBy = {};
      if (elem.orderBy) {
        orderBy = {
          orderBy: elem.orderBy,
          direction: elem.direction
        };
      }

      if (this.filterActive) {
        await this.filterBillsRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.billsPages.current_page,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchBillsRequest({
          value: this.search,
          page: this.billsPages.current_page,
          paginate: true,
          ...orderBy
        });
      } else {
        await this.billsRequest({
          page: this.billsPages.current_page,
          updateLocal: false,
          ...orderBy
        });
      }
      this.loadingBills = false;
    },
    async doRequests(reset) {
      this.loadingBills = true;
      if (reset) {
        this.billsPages.current_page = this.initialPage;
        this.$store.commit("setFilterActive", false);
        this.$store.commit("setBillsSort", {
          orderBy: "",
          direction: "",
          name: ""
        });
      }

      if (this.filterActive) {
        await this.filterBillsRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.billsPages.current_page
        });
      } else if (this.search.length > 0) {
        await this.searchBillsRequest({
          value: this.search,
          page: this.billsPages.current_page,
          paginate: true
        });
      } else {
        await this.billsRequest({ page: this.billsPages.current_page });
      }

      this.loadingBills = false;
    },
    async selectPage(val) {
      this.loadingBills = true;
      let orderBy = {};
      if (this.billsSort.orderBy) {
        orderBy = {
          orderBy: this.billsSort.orderBy,
          direction: this.billsSort.direction
        };
      }

      if (this.filterActive) {
        await this.filterBillsRequest({
          filters: this.exportParams?.payload?.filters,
          page: val,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchBillsRequest({
          value: this.search,
          page: val,
          ...orderBy
        });
      } else {
        await this.billsRequest({
          page: val,
          ...orderBy
        });
      }

      this.loadingBills = false;
    }
  },
  async mounted() {
    await this.doRequests(true);
    this.$store.commit("resetFilters");
  },
  beforeRouteUpdate(to, from, next) {
    if (to.hash.length > 0) {
      return;
    }
    if (from.name === "filterBill") {
      this.search = "";
    }
    if (from.name === "importBills") {
      this.doRequests();
    }
    if (to.query.reload) {
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#bills {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
.balance-question {
  max-width: 400px;
  .we-label {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $medium;
    color: var(--text-2);
    padding-top: 8px;
    margin-bottom: 8px;
  }

  .calc {
    padding-bottom: 16px;
    font-size: 14px;
    text-align: center;
    font-weight: $medium;
  }

  .obs {
    border-top: 1px solid var(--line);
    font-size: 12px;
    color: var(--text-2);
    text-align: center;
    padding: 8px 40px 4px 40px;
  }
}
</style>
